<template>
  <div class="attendance-history-page">
    <v-row justify="center">
      <v-col md="6">
        <div>
           <!-- <v-card class="mt-2 mx-1" outlined elevation="0">
              <v-tabs
              
                fixed-tabs
                background-color="primary"
                dark
                v-model="attendanceTypeTab"
              >
                <v-tab class="non-focused tab-text"
                  >Subject Attendance</v-tab
                >
                <v-tab class="non-focused tab-text">Daily Attendance</v-tab>
              </v-tabs>
           </v-card> -->
          <intract-smart-list
            class="mt-2"
            ref="attendance-history-smart-list"
            :endpoint="attendanceHistoryEndpoint"
            paginated
            disable-search
            :filter-fields="filterFields"
          >
            <template v-slot:custom-filter--dateRange>
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    solo
                    clearable
                    @click:clear="date = null"
                    :value="formattedDateRangeString"
                    label="Filter by Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" range>
                  <v-row no-gutters>
                    <v-col cols="12" class="text-center">
                      <span
                        v-if="date && date.length == 1"
                        class="text-caption red--text"
                        >Please select End Date as well</span
                      >
                    </v-col>
                    <v-col class="text-right">
                      <v-btn text color="primary" @click="modal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        :disabled="date && date.length == 1"
                        color="primary"
                        @click="$refs.dialog.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-date-picker>
              </v-dialog>
            </template>
            <template v-slot:list-item="{ item: attendance }">
              <v-list-item
                :to="{
                  name: 'EditAttendance',
                  params: {
                    attendanceId: attendance.id,
                    ... attendanceTypeTab == 0 ? {subject: attendance.subject} : {},
                    room: attendance.room,
                    daily: attendanceTypeTab == 1,
                    attendanceDate: moment(attendance.date).format(
                      'YYYY-MM-DD'
                    ),
                  },
                }"
                class="non-focused"
              >
                <v-list-item-content>
                  <v-list-item-subtitle>
                    <p class="pa-0 ma-0 font-weight-bold">
                      {{ moment(attendance.date).format("ll") }}
                    </p>
                  </v-list-item-subtitle>
                  <v-list-item-subtitle>{{
                    attendanceTypeTab == 0 ? attendance.faculty.full_name : attendance.creator.full_name
                  }}</v-list-item-subtitle>

                  <v-list-item-subtitle
                    >{{ attendance.room.sections }}
                    {{ attendanceTypeTab == 0 ? `| ${attendance.subject.name}` : '' }}</v-list-item-subtitle
                  >

                  <v-list-item-subtitle class="text-caption"
                    >Recorded on
                    {{
                      moment(attendanceTypeTab == 0 ? attendance.created : attendance.updated).format("lll")
                    }}</v-list-item-subtitle
                  >
                </v-list-item-content>
                <v-list-item-action class="justify-center align-center">
                  <v-row>
                    <div
                      v-if="attendanceTypeTab == 0"
                      :set="
                        (perc =
                          (attendance.attendees.length * 100) /
                          attendance.total)
                      "
                    >
                      <v-progress-circular :value="perc" color="primary">
                        <p class="pa-0 ma-0 percentage-text primary--text">
                          {{ Math.round(perc) }}%
                        </p>
                      </v-progress-circular>
                    </div>
                    <div v-else>
                      <v-progress-circular :value="attendance.attendance_percentage" color="primary">
                        <p class="pa-0 ma-0 percentage-text primary--text">
                          {{ Math.round(attendance.attendance_percentage) }}%
                        </p>
                      </v-progress-circular>
                    </div>
                    <intract-drop-down-menu
                      v-if="
                        (attendance.faculty && attendance.faculty.id == currentUser.id) ||
                        (attendance.creator && attendance.creator.id == currentUser.id) ||
                        currentUser.is_admin
                      "
                      :options-list="getDropdownOptions(attendance)"
                    />
                  </v-row>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
            </template>
          </intract-smart-list>
        </div>
      </v-col>
    </v-row>
    <!-- delete dialog -->
    <confirmation-dialog
      :visible="showDeleteDialog"
      title="Are you sure you want to delete this attendance record?"
      description="This action cannot be undone. This will also send a notification to the student."
      @successCallback="deleteAttendance"
      @failureCallback="
        showDeleteDialog = false;
        toBeDeletedAttendance = null;
      "
      delete-dialog
    />
  </div>
</template>
<script>
import Mixins from "@utils/mixins";
import EventBus from "@utils/event_bus";
import moment from "moment";
import IntractSmartList from "@components/generics/IntractSmartList";
import IntractDropDownMenu from "@components/generics/IntractDropDownMenu";
export default {
  name: "AttendanceHistory",
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  components: {
    IntractDropDownMenu,
    IntractSmartList,
  },
  props: {
    room: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      moment,
      roomId: this.$route.params.roomId,
      date: null,
      modal: false,
      toBeDeletedAttendance: null,
      showDeleteDialog: false,
      attendanceTypeTab: 1,
    };
  },
  computed: {
    formattedDateRangeString() {
      if (!this.date) return null;
      if (this.date.length == 0) return "";
      else if (this.date.length == 1)
        return moment(this.date[0]).format("ll") + " - ";
      else
        return (
          moment(this.date[0]).format("ll") +
          " - " +
          moment(this.date[1]).format("ll")
        );
    },
    attendanceHistoryEndpoint() {
      var roomFilter = "room=" + this.roomId;
      var facultyFilter = null
      if(this.attendanceTypeTab == 0)
        facultyFilter = "faculty=" + this.currentUser.id;
      else
        facultyFilter = "creator=" + this.currentUser.id;
      var dateFilter = "";
      if (this.date && this.date.length == 2) {
        var startDate,
          endDate = null;
        if (moment(this.date[0]).isSameOrBefore(moment(this.date[1]))) {
          startDate = this.date[0];
          endDate = this.date[1];
        } else {
          startDate = this.date[1];
          endDate = this.date[0];
        }
        if(this.attendanceTypeTab == 1){
          startDate = startDate.format('YYYY-MM-DD')
          endDate = endDate.format('YYYY-MM-DD')
        }
        dateFilter = `date__gte=${moment(startDate).format(
          this.Helper.dateURLFormat
        )}&date__lte=${moment(endDate).format(this.Helper.dateURLFormat)}`;
      }

      var url = null
      if(this.attendanceTypeTab == 0)
        url = this.endpoints.attendanceViewSet
      else
        url = this.endpoints.classroomAttendanceViewSet

      return this.Helper.addUrlParams(url, [
        "ordering=-created",
        this.roomId ? roomFilter : facultyFilter,
        dateFilter,
      ]);
    },
    onlyFaculty() {
      return this.currentUser.is_faculty && !this.currentUser.is_admin;
    },
    filterFields() {
      return {
        dateRange: {
          model: null,
          items: [],
          label: "Filter by Date",
          // itemValue: "id",
          custom: true,
          // itemText: "name",
          // param: "subject__in",
          //   dynamicParam: true
        },
      };
    },
  },
  methods: {
    getDropdownOptions(attendance) {
      var self = this;
      return [
        // {
        //   obj: transaction,
        //   value: 1,
        //   text: "Edit",
        //   icon: "mdi-pencil",
        //   type: "edit",
        //   action() {
        //     self.createEditFeeTransactionSheet.editId = transaction.id;
        //     self.createEditFeeTransactionSheet.visible = true;
        //   },
        // },
        {
          obj: attendance,
          value: 1,
          text: "Delete",
          icon: "mdi-delete",
          type: "delete",
          action() {
            self.toBeDeletedAttendance = attendance;
            self.showDeleteDialog = true;
          },
        },
      ];
    },

    async deleteAttendance() {
      var objId = this.toBeDeletedAttendance.id;
      var url = null
      if(this.attendanceTypeTab == 0)
        url = this.endpoints.attendanceViewSet + objId + "/";
      else
        url = this.endpoints.classroomAttendanceViewSet + objId + "/";
      this.showDeleteDialog = false;
      this.toBeDeletedAttendance = null;
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.DELETE
      );
      if (response) {
        this.showSnackbar({
          title: "Attendance record was successfully deleted!",
          type: "success",
        });
        this.$refs["attendance-history-smart-list"].removeItem(objId);
      }
    },

    async setListeners() {
      var self = this;
      EventBus.$on("attendance__update_attendance_object", attendanceObj => {
        console.log("attendance__update_attendance_object")
        if (self.$refs["attendance-history-smart-list"])
          self.$refs["attendance-history-smart-list"].replaceItem(
            attendanceObj.id,
            attendanceObj
          );
      });
    },
    async onRefresh() {
      if (this.$refs["attendance-history-smart-list"])
        this.$refs["attendance-history-smart-list"].getItems();
    },
  },
  beforeRouteLeave(to, from, next) {
    if (to.name == "Attendance") {
      EventBus.$emit("keep_alive__destroy_view", "AttendanceHistory");
    }
    next();
  },
  created() {
    this.setListeners();
  },
};
</script>
<style lang="sass" scoped>
.percentage-text
  font-size: 9px
</style>